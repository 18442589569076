$(function(){
  const search_button_selector = '#zip_code_search_button';
  const search_value_selector = '#user_zip_code';

  const search_button_element = $(search_button_selector);
  const search_value_element = $(search_value_selector);
  if(search_button_element.length > 0 && search_value_element.length > 0){
    $(search_button_element).on('click', function(){
      $.ajax({
        type: 'GET',
        url: `/private_api/convert_zip_code`,
        data: {
          zip_code: search_value_element.val()
        }
      }).done(function(data) {
        console.log(data);
        const addresses = data.data;

        const prefecture_code_input_selector = '#user_geo_master_jp_prefecture_code';
        const city_code_input_selector = '#user_geo_master_jp_city_code';
        const other_address_input_selector = '#user_other_address';

        // 全て初期状態に
        $(prefecture_code_input_selector).children('option').remove();
        $(city_code_input_selector).children('option').remove();
        $(other_address_input_selector).val('');

        // 全てを有効にする
        $(prefecture_code_input_selector).prop('disabled', false);
        $(city_code_input_selector).prop('disabled', false);
        $(other_address_input_selector).prop('disabled', false);

        addresses.forEach((addresse)=>{
          //// 都道府県
          const current_prefecture_options = $(prefecture_code_input_selector).children('option');
          const current_prefecture_codes = current_prefecture_options.length > 0 ? current_prefecture_options.map((_, option) => $(option).val()).toArray() : [];
          // 重複していない場合、選択肢を追加
          if(current_prefecture_codes.indexOf(addresse.prefecture_code.toString()) < 0){
            let prefecture_code = ('00' + addresse.prefecture_code.toString()).slice(-2);;
            $(prefecture_code_input_selector).append($('<option>').val(prefecture_code).text(addresse.prefecture));
          }

          //// 市区町村
          const current_city_options = $(city_code_input_selector).children('option');
          const current_city_codes = current_city_options.length > 0 ? current_city_options.map((_, option) => $(option).val()).toArray() : [];
          // 重複していない場合、選択肢を追加
          if(current_city_codes.indexOf(addresse.city_code) < 0){
            $(city_code_input_selector).append($('<option>').val(addresse.city_code).text(addresse.city));
          }
        });

        //// 町域以下(複数の場合は入力しない)
        if(addresses.length == 1){
          $(other_address_input_selector).val(addresses[0].town);
        }
      }).fail(function() {
        alert('郵便番号が間違っています');
      });

      return false;
    });
  }

  // 郵便番号フォームでのエンター
  $(search_value_selector).on('keydown', function(e) {
    if( e.keyCode == 13 ) {
      $(search_button_element).trigger('click');
      e.preventDefault();
    }
  });
});
